import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../../api/config';
import './Preventions.css'

const Prevention = () => {
    const [preventions, setPreventions] = useState([]);
    const { malwareId } = useParams();

    useEffect(() => {
        const fetchPrevention = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/malwares/${malwareId}/preventions`);  // Use the BASE_URL
                setPreventions(response.data);
            } catch (error) {
                console.error("Error fetching malware prevention:", error);
            }
        };

        fetchPrevention();
    }, [malwareId]);

    return (
        <div className="container mt-5">
            <h2 className="mb-4">Preventions for Malware ID: {malwareId}</h2>
            {preventions.map(prevention => (
                <div key={prevention.id} className="card mb-3">
                    <div className="card-header">
                        {prevention.name}
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">Date Added: {prevention.added_on}</h5>
                        <p className="card-text">
                            {prevention.description?.body}
                        </p>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Prevention;
