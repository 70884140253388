import React, { useState, useEffect } from 'react';
import { getFaqs } from '../../api';

const FAQs = () => {
    const [faqs, setFaqs] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const response = await getFaqs();
            setFaqs(response.data);
        };

        fetchData();
    }, []);

    return (
        <div>
            <h2>FAQs</h2>
            {faqs.map(faq => (
                <div key={faq.id}>
                    <h3>{faq.question}</h3>
                    <p>{faq.answer}</p>
                </div>
            ))}
        </div>
    );
}

export default FAQs;
