import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../../api/config';  // Import the BASE_URL

const MalwareDetailsWithSteps = () => {
    const [malware, setMalware] = useState(null);
    const { malwareId } = useParams();

    useEffect(() => {
        const fetchDetails = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/malwares/${malwareId}`);  // Use the BASE_URL
                setMalware(response.data);
            } catch (error) {
                console.error("Error fetching malware details:", error);
            }
        };

        fetchDetails();
    }, [malwareId]);

    return (
        <div>
            {malware && (
                <>
                    <h2>{malware.name}</h2>
                    <p>{malware.description}</p>
                    <ul>
                        {malware.steps && malware.steps.map(step => (
                            <li key={step.id}>{step.description.body}</li>
                        ))}
                    </ul>
                    <h3>Malware Steps:</h3>
                    <ul>
                        {malware.malware_steps && malware.malware_steps.map(step => (
                            <li key={step.id}>{step.description.body}</li>
                        ))}
                    </ul>
                </>
            )}
        </div>
    );
}

export default MalwareDetailsWithSteps;
