import React, { useState, useEffect } from 'react';
import { getAllMalwareSteps } from '../../api';

const AllMalwareStepsList = () => {
    const [steps, setSteps] = useState([]);

    useEffect(() => {
        const getSteps = async () => {
            try {
                const data = await getAllMalwareSteps();
                setSteps(data);
            } catch (error) {
                console.error("Error fetching all malware steps:", error);
            }
        };

        getSteps();
    }, []);

    return (
        <div>
            <h2>All Malware Steps</h2>
            <ul>
                {steps.map(step => (
                    <li key={step.id}>{step.description.body}</li>
                ))}
            </ul>
        </div>
    );
}

export default AllMalwareStepsList;
