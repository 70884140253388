import axios from 'axios';

const BASE_URL = 'https://malware-api-2hi1.onrender.com/api/v1';

// Users
export const getUsers = () => {
  return axios.get(`${BASE_URL}/users`);
}

// FAQs
export const getFaqs = () => {
  return axios.get(`${BASE_URL}/faqs`);
}

// Malwares
export const getMalwares = () => {
  return axios.get(`${BASE_URL}/malwares`);
}

export const getMalwareById = (id) => {
  return axios.get(`${BASE_URL}/malwares/${id}`);
}

// Malware Steps
export const getAllMalwareSteps = () => {
  return axios.get(`${BASE_URL}/malware_steps`);
}

export const getStepsForMalware = (malwareId) => {
  return axios.get(`${BASE_URL}/malwares/${malwareId}/malware_steps`);
}

export const getSpecificStepForMalware = (malwareId, stepId) => {
  return axios.get(`${BASE_URL}/malwares/${malwareId}/malware_steps/${stepId}`);
}

// Malware Preventions
export const getMalwarePreventions = () => {
  return axios.get(`${BASE_URL}/malware_preventions`);
}

export const getMalwarePreventionById = (id) => {
  return axios.get(`${BASE_URL}/malware_preventions/${id}`);
}

// Preventions
export const getPreventions = () => {
  return axios.get(`${BASE_URL}/preventions`);
}

export const getPreventionById = (id) => {
  return axios.get(`${BASE_URL}/preventions/${id}`);
}

// Posts
export const getPosts = () => {
  return axios.get(`${BASE_URL}/posts`);
}

export const getPostById = (postId) => {
  return axios.get(`${BASE_URL}/posts/${postId}`);
}

// Comments for a specific post
export const getCommentsForPost = (postId) => {
  return axios.get(`${BASE_URL}/posts/${postId}/comments`);
}

// Preventions for a specific malware
export const getPreventionsForMalware = (malwareId) => {
  return axios.get(`${BASE_URL}/malwares/${malwareId}/preventions`);
}
