import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

function MalwareDetail() {
  const [malware, setMalware] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    // Fetch specific malware by ID from API using Axios
    axios.get(`http://localhost:3000/api/v1/malwares/${id}`)
      .then(response => {
        setMalware(response.data);
      })
      .catch(error => {
        console.error('Error fetching malware detail:', error);
      });
  }, [id]);

  if (!malware) return <div>Loading...</div>;

  return (
    <div className="malware-detail">
      <h2>{malware.name}</h2>
      <p>Type: {malware.malware_type}</p>
      <p>Symptoms: {malware.symptoms}</p>
      <p>Impact: {malware.impact}</p>
      {/* Display other details as needed */}
    </div>
  );
}

export default MalwareDetail;
