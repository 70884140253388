// Components/posts/PostDetail.js

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const PostDetail = () => {
    const [post, setPost] = useState(null);
    const { id } = useParams();

    useEffect(() => {
        const fetchPostDetail = async () => {
            try {
                const response = await axios.get(`http://localhost:3000/api/v1/posts/${id}`);
                setPost(response.data);
            } catch (error) {
                console.error("Error fetching post details:", error);
            }
        };

        fetchPostDetail();
    }, [id]);

    if (!post) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <h2>{post.title}</h2>
            <p>{post.body}</p>
            {/* Add any other fields you want to display here */}
        </div>
    );
}

export default PostDetail;
