import React, { useState, useEffect } from 'react';
import axios from 'axios';

const PreventionsList = () => {
    const [preventions, setPreventions] = useState([]);

    useEffect(() => {
        const fetchPreventions = async () => {
            try {
                const response = await axios.get('/api/v1/preventions');
                setPreventions(response.data);
            } catch (error) {
                console.error("Error fetching all preventions:", error);
            }
        };

        fetchPreventions();
    }, []);

    return (
        <div>
            <h2>All Preventions</h2>
            <ul>
                {preventions.map(prevention => (
                    <li key={prevention.id}>
                        <strong>Name:</strong> {prevention.name} <br />
                        <strong>Description:</strong> {prevention.description} <br />
                        <strong>Added On:</strong> {prevention.added_on} <br />
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default PreventionsList;
