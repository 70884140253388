import HomePage from '../Components/HomePage/HomePage';
import MalwareList from '../Components/Malwares/MalwareList';
import MalwareDetail from '../Components/Malwares/MalwareDetail';
import AllMalwareStepsList from '../Components/Prevention_steps/AllmalwareStepsList'; // Import the component
import MalwareSpecificStepsList from '../Components/Prevention_steps/MalwareDetailsWithSteps'; // Import the component
import MalwareDetailsWithSteps from '../Components/Prevention_steps/MalwareDetailsWithSteps'; // Import the component
import MalwareStepDetail from '../Components/Prevention_steps/PreventionStepDetail';
import PreventionList from '../Components/preventions/PreventionList';
import PreventionDetail from '../Components/preventions/MalwareAndPreventionDetail';
import PostList from '../Components/posts/PostList';
import PostDetail from '../Components/posts/PostDetail';
import LoginForm from '../Components/auth/LoginForm';
import RegisterForm from '../Components/auth/RegisterForm';
import CommentList from '../Components/comments/CommentList';
import FAQList from '../Components/FAQ/FAQList';

const routes = [
  {
    path: '/',
    element: <HomePage />
  },
  {
    path: '/malwares',
    element: <MalwareList />
  },
  {
    path: '/malwares/:malwareId',
    element: <MalwareDetail />
  },
  {
    path: '/malwares/:malwareId/steps',
    element: <MalwareSpecificStepsList />
  },
  {
    path: '/malwares/:malwareId/details',
    element: <MalwareDetailsWithSteps />
  },
  {
    path: '/malwares/:malwareId/steps/:id',
    element: <MalwareStepDetail />
  },
  {
    path: '/malwares/:malwareId/preventions',
    element: <PreventionDetail />
  },
  {
    path: '/malware_steps',
    element: <AllMalwareStepsList />
  },
  {
    path: '/preventions',
    element: <PreventionList />
  },
  {
    path: '/posts',
    element: <PostList />
  },
  {
    path: '/posts/:postId',
    element: <PostDetail />
  },
  {
    path: '/posts/:postId/comments',
    element: <CommentList />
  },
  {
    path: '/faqs',
    element: <FAQList />
  },
  {
    path: '/login',
    element: <LoginForm />
  },
  {
    path: '/register',
    element: <RegisterForm />
  }
  
];

export default routes;
