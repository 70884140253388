import React from 'react';
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap';
import './HomePage.css';

function HomePage() {
    return (
        <Container fluid className="homepage">

            {/* Header/Hero Section */}
            <header className="homepage-header text-white text-center py-5">
                <h1>Welcome to MalwareInfo</h1>
                <p>Your guide to understanding and preventing malware threats.</p>
                <Button variant="light">Learn More</Button>
            </header>

            {/* Features Section */}
            <section className="features-section py-5">
                <h2 className="text-center mb-4">Key Features</h2>
                <Row className="justify-content-center"> {/* Centering cards */}
                    {["Real-time malware updates", "Guides from experts", "Secure browsing tips"].map((feature, index) => (
                        <Col md={3} key={index}>
                            <Card className="mb-4 shadow-sm">
                                <Card.Body className="text-center">
                                    <Card.Title>{feature}</Card.Title>
                                    <Card.Text>Short description about the feature.</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </section>

            {/* Newsletter Signup */}
            <section className="newsletter-section py-5">
                <Row className="justify-content-center">
                    <Col md={6}>
                        <Card className="shadow-sm">
                            <Card.Body className="text-center">
                                <h2>Stay Updated</h2>
                                <p>Subscribe to our newsletter to stay updated with the latest malware threats and preventive measures.</p>
                                <Form>
                                    <Form.Group>
                                        <Form.Control type="email" placeholder="Enter email" />
                                    </Form.Group>
                                    <Button variant="dark" type="submit">Subscribe</Button>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </section>
        </Container>
    );
}

export default HomePage;
