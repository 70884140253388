import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getMalwares } from '../../api';
import './Malwares.css'; // Ensure this path is correct

const MalwareList = () => {
    const [malwares, setMalwares] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await getMalwares();
                setMalwares(result.data);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    return (
        <div className="malware-list-container">
            {malwares.length > 0 ? (
                malwares.map(({ id, name, category, symptoms, impact, date_detected }) => (
                    <div key={id} className="malware-item">
                        <h3>{name}</h3>
                        <p>Category: {category}</p>
                        <p>Symptoms: {symptoms}</p>
                        <p>Impact: {impact}</p>
                        <p>Date Detected: {date_detected}</p>
                        <div className="malware-options">
                            <Link to={`/malwares/${id}/preventions`}>View Prevention</Link>
                            <Link to={`/malwares/${id}/steps`}>View Steps</Link>
                        </div>
                    </div>
                ))
            ) : (
                <p>No malwares available.</p>
            )}
        </div>
    );
}

export default MalwareList;
