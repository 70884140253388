import React from 'react';
import Footer from './Footer';  // Assuming you have a Footer component in the same directory
import Navbar from './Navbar';  // Similarly, assuming you have a Navbar component
import './layout.css';

function Layout(props) {
  return (
    <div className="site-container">
      <Navbar />
      <div className="content-container">
        {props.children}  {/* This will render whatever component is wrapped by Layout */}
      </div>
      <Footer />
    </div>
  );
}

export default Layout;
