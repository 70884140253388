import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';

function MainNavbar() {
  return (
    <Navbar expand="lg" variant="dark" className="nav-custom">
      <Container>
        <Navbar.Brand href="/">MalwareInfo</Navbar.Brand>
        <Navbar.Toggle aria-controls="main-navbar-nav" />
        <Navbar.Collapse id="main-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/malwares">Malwares</Nav.Link>
            <Nav.Link href="/posts">Posts</Nav.Link>
            <Nav.Link href="/Malware_steps">Fix</Nav.Link>
            <Nav.Link href="/Preventions">Prevention</Nav.Link>
            <NavDropdown title="Resources" id="resource-nav-dropdown">
              <NavDropdown.Item className="nav-dropdown-custom" href="/malwares">Malware Steps</NavDropdown.Item> {/* Updated link */}
              <NavDropdown.Item className="nav-dropdown-custom" href="/updates">Latest Updates</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item className="nav-dropdown-custom" href="/community">Community Support</NavDropdown.Item>
              <NavDropdown.Item className="nav-dropdown-custom" href="/faqs">Faqs</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default MainNavbar;
